import getConfig from 'next/config'

const isProd =
  process.env.VERCEL_ENV === 'production' ||
  (process.browser &&
    ['gamma.app', 'gamma-app.vercel.app'].some((domain) =>
      window.location.hostname.endsWith(domain)
    ))

const nextConfig = getConfig()

const publicRuntimeConfig = nextConfig
  ? nextConfig.publicRuntimeConfig
  : {
      API_HOST: !isProd ? 'localhost:7000' : 'api.dev.gamma.app',
    }

let FINAL_IS_DESKTOP = false
let FINAL_API_HOST = ''
let FINAL_OPTIMIZELY_SDK_KEY = ''
let FINAL_COOKIE_DOMAIN = ''
let FINAL_HOCUSPOCUS_URL = ''
let FINAL_HOCUSPOCUS_PATH = ''
if (process.browser) {
  const isAlpha = window.location.hostname === 'alpha.gamma.app'
  FINAL_API_HOST =
    // Detect alpha specifically because vercel doesnt allow different config values
    isAlpha ? 'https://api.alpha.gamma.app' : publicRuntimeConfig.API_HOST
  FINAL_OPTIMIZELY_SDK_KEY = isAlpha
    ? 'VhFmDNnZrcP9rfaYkArWm'
    : 'TNQjZ8SsvXQZfVdiTAySY'
  FINAL_COOKIE_DOMAIN = isProd
    ? isAlpha
      ? '.alpha.gamma.app'
      : '.dev.gamma.app'
    : 'localhost'
  FINAL_IS_DESKTOP = !!window.__GAMMA_DESKTOP__
  // Controls the path to use a Hocuspocus backup
  FINAL_HOCUSPOCUS_PATH =
    new URLSearchParams(window.location.search).get('use_backup') || ''
  FINAL_HOCUSPOCUS_URL = `${
    (publicRuntimeConfig.HOCUSPOCUS_WS_URL as string) || 'ws://127.0.0.1:7001'
  }`
}

const FINAL_WS_HOST = (FINAL_API_HOST || '')
  .replace('https://', '')
  .replace('http://', '')

const API_ENV = FINAL_API_HOST.endsWith('gamma.app')
  ? FINAL_API_HOST.match(/([^.]+)\.gamma.app/)![1]
  : 'local'

console.log('[config]', { publicRuntimeConfig, FINAL_API_HOST, FINAL_WS_HOST })

// Ported from packages/server/src/config
const config = (key: string) =>
  ({
    API_HOST: FINAL_API_HOST ? FINAL_API_HOST : 'https://localhost:7000',
    API_ENV: API_ENV,
    WS_HOST: FINAL_WS_HOST,
    WS_PROTOCOL: process.env.WS_PROTOCOL || 'wss',
    OPTIMIZELY_SDK_KEY: FINAL_OPTIMIZELY_SDK_KEY,
    COOKIE_DOMAIN: FINAL_COOKIE_DOMAIN,
    ENV: (process.env.VERCEL_ENV as string) || 'dev',
    DEV: process.env.VERCEL_ENV !== 'production',
    LOG_LEVEL: (process.env.LOG_LEVEL as string) || 'error',
    IS_DESKTOP: FINAL_IS_DESKTOP,
    IS_PROD: isProd,
    HOCUSPOCUS_WS_URL: FINAL_HOCUSPOCUS_URL,
    HOCUSPOCUS_PATH: FINAL_HOCUSPOCUS_PATH,
  }[key])

export default config
